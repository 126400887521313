document.querySelectorAll(".link-home").forEach(function (el) {
  el.addEventListener(
    "click",
    function () {
      document.getElementById("home").classList.add("z-20");
      document.getElementById("about").classList.remove("z-20");
      document.getElementById("mural").classList.remove("z-20");
    },
    false
  );
});

document.querySelectorAll(".link-about").forEach(function (el) {
  el.addEventListener(
    "click",
    function () {
      document.getElementById("home").classList.remove("z-20");
      document.getElementById("about").classList.add("z-20");
      document.getElementById("mural").classList.remove("z-20");
    },
    false
  );
});

document.querySelectorAll(".link-mural").forEach(function (el) {
  el.addEventListener(
    "click",
    function () {
      document.getElementById("home").classList.remove("z-20");
      document.getElementById("about").classList.remove("z-20");
      document.getElementById("mural").classList.add("z-20");
    },
    false
  );
});
